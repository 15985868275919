import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {SiteDispatchContext, SiteStateContext} from "../providers/siteProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faSpinner} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import TextareaAutosize from 'react-textarea-autosize';
import {isPermitted} from "../utils/CommonUtil";

export const MessageInputComponent = ({submitFunc, addListFunc, msgObj, placeHolder, changeFocus, isInput = false}) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState({error: '', message: ''});
    const [isLoading, setIsLoading] = useState(false);
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const handleSubmit = async() => {
        setError({error: '', message: ''})
        const data = {...msgObj, message: message};

        try {
            setIsLoading(true)
            const {data: messageData} = await submitFunc({data: data})
            setIsLoading(false)
            // 戻り値のmessageIdをセット
            addListFunc({...data, messageId: messageData.messageId})
            setMessage('')
        } catch(e) {
            setIsLoading(false)
            console.log(e.response)
            if(!e.response) {
                dispatch({
                    type: 'CHANGE_IS_ERROR',
                    payload: true,
                })
            }
            switch(e.response.status) {
                case 400:
                    setError(e.response.data)
                    break;
                case 404:
                    dispatch({
                        type: 'CHANGE_IS_ERROR',
                        payload: true,
                    })
                    break;
                default:
                    dispatch({
                        type: 'CHANGE_IS_ERROR',
                        payload: true,
                    })
            }
        }

    }

    const checkLengthText = () => message.length <= 200;

    const isError = () => {
        return !checkLengthText() || error.message
    }

    return (
      <div className={classNames('message_input_wrapper', isError() && 'message_input_wrapper_invalid')}>
          {
              isPermitted(state) ?
                state.user?.screenName
                  ?
                  <div className={classNames('message_input')}>
                      {
                          isInput
                            ?
                            <input type={'text'} onFocus={changeFocus} onBlur={changeFocus} className={classNames('message_input_text', message && checkLengthText() && 'is-input', isError() && 'message_input_invalid')} placeholder={placeHolder} value={message} onChange={(e) => { setMessage(e.currentTarget.value )}} />
                            :
                            <TextareaAutosize maxRows={6} onFocus={changeFocus} onBlur={changeFocus} className={classNames('message_input_text', message && checkLengthText() && 'is-input', isError() && 'message_input_invalid')} placeholder={placeHolder} value={message} onChange={(e) => { setMessage(e.currentTarget.value )}}/>
                      }
                      {/*<textarea onFocus={changeFocus} onBlur={changeFocus} className={classNames('message_input_text', message && checkLengthText() ? 'is-input' : '')} placeholder={placeHolder} value={message} onChange={(e) => { setMessage(e.currentTarget.value )}} />*/}
                      <button className={'btn_circle'} onClick={handleSubmit} disabled={!checkLengthText() || isLoading}>
                          { isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faPaperPlane}/>}
                      </button>
                  </div>
                  :
                  <Link to={'/user/edit'} className={classNames('message_input')} >
                      <textarea className={classNames('message_input_text')} placeholder={'ニックネームを登録して投稿'} value={message} onChange={(e) => { setMessage(e.currentTarget.value )}} />
                  </Link>
                :  <div className={classNames('message_input')}><p>本日のイベントに参加されない方の投稿はできません。</p></div>

          }

          { !checkLengthText() && <p className='error_text'>文字数は200文字以下にしてください。</p> }
          { error.message && <p className='error_text'>{error.message}</p> }
      </div>
    )
};

/* usage example */
// const ExampleMessageInput = () => {
//     const api = {
//         getMessages: {url: () => '/room/list', method: 'GET'},
//     }
//
//     const [{data, error}, postMessage] = useAxiosApi({
//         url: api.postMessage.url(),
//         method: api.postMessage.method,
//     }, {manual: true})
//
//     const msgObj = {
//         roomId: '',
//         memberId: '',
//         message: '',
//         userId: '',
//         postTime: '',
//         screenName: '',
//     }
//
//     const addMessage = (msg, date) => {
//     }
//
//     return (
//       <MessageInput addListFunc={addMessage} submitFunc={postMessage} msgObj={msgObj} />
//     )
// }
