import classes from '../../../css/modules/Message.module.scss';
import React, {useContext, useEffect, useState, useCallback, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {Header} from '../../components/Header'
import {SiteDispatchContext, SiteStateContext} from "../../providers/siteProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faBan} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import {format, now} from "../../utils/DateUtil";
import {useAxiosApi} from '../../providers/axiosProvider'
import {isBlockedUser, setBlockedUser} from "../../utils/BlockedUsers";

const api = {
    getMessage: {url: () => '/cam/message', method: 'GET'},
    violationMessage: {url: () => '/cam/violation', method: 'POST'},
}

const UserBan = ({blockedUserId, setBlockedUserId, setBlockedFlg}) => {
    const dispatch = useContext(SiteDispatchContext)


    const handleSubmit = async () => {
        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: true
        })

        setBlockedUser(blockedUserId)
        setBlockedFlg(true)

        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: false
        })
        setBlockedUserId(null);
    }

    return (
        <div className={classNames(classes.report, blockedUserId !== null ? classes.report_active : '')}>
            <div className={classes.report_inner}>
                <div className={classes.report_top}>
                    <p>このユーザーをブロックする</p>
                    <p className={classes.report_description}>ブロックすると、このユーザーはチャットルームで表示されなくなります。</p>
                </div>

                <div className={classes.report_bottom}>
                    <button className={classNames('btn_link cyan')} onClick={() => {
                        handleSubmit()
                    }}>このユーザーをブロックする
                    </button>
                    <button className={'btn_link gray'} onClick={() => {
                        setBlockedUserId(null)
                    }}>キャンセル
                    </button>
                </div>
            </div>
        </div>
    )
}

const MessageReport = ({reportId, setReportId, userId, shardId, blockedUserId, setBlockedUserId, setBlockedFlg}) => {
    const [reason, setReason] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [{data}, violationMessage] = useAxiosApi({
        url: api.violationMessage.url(),
        method: api.violationMessage.method,
        data: {
            roomId: state.currentArtist.roomId,
            shardId: shardId,
            messageId: reportId,
            userId: userId,
            reason: reason
        }
    }, {manual: true})

    const handleSubmit = async () => {
        try {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: true
            })

            await violationMessage();

            setReportId(null);

        } catch (e) {
            switch (e.response.status) {
                case 400:
                    // 既に通報済みの場合は成功した時と同じ振る舞いにする
                    console.log(e.response.data.message)
                    setReportId(null);
                    break;
                default:
                    // その他のエラーの場合はエラーメッセージを表示する
                    console.log(e)
                    setErrorMessage(e.response.data.message)
            }
        } finally {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })
        }
    }

    const reportContent = (
        errorMessage ?
            <div className={classes.report_inner}>
                <div className={classes.report_top}>
                    <p>{errorMessage}</p>
                </div>

                <div className={classes.report_bottom}>
                    <button className={'btn_link gray'} onClick={() => {
                        setReportId(null)
                        setErrorMessage(null)
                    }}>閉じる
                    </button>
                </div>
            </div>
            :
            <div className={classes.report_inner}>
                <div className={classes.report_top}>
                    <p>この投稿を通報します</p>
                    <textarea placeholder="通報理由を記載してください" name="report_reason" value={reason}
                              onChange={(e) => {
                                  setReason(e.currentTarget.value)
                              }}/>
                </div>

                <div className={classes.report_bottom}>
                    <button className={classNames('btn_link cyan')} disabled={!reason} onClick={() => {
                        handleSubmit()
                    }}>このコメントを通報する
                    </button>
                    <button className={'btn_link gray'} onClick={() => {
                        setReportId(null)
                        setReason('')
                    }}>キャンセル
                    </button>
                </div>
            </div>
    )

    return (
        <div className={classNames(classes.report, reportId !== null ? classes.report_active : '')}>
            <div className={classes.report_inner}>
                {reportContent}
            </div>
        </div>
    )
}

const CameraMessageDetail = () => {
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [message, setMessage] = useState({reply: []});
    const [reportId, setReportId] = useState(null);
    const [blockedUserId, setBlockedUserId] = useState(null);
    const [blockedFlg, setBlockedFlg] = useState(false);

    const {roomId, shardId, messageId} = useParams();

    const [{data: messageData, loading}, getMessage] = useAxiosApi({
        url: api.getMessage.url(),
        method: api.getMessage.method,
        params: {
            roomId: roomId,
            shardId: shardId,
            messageId: messageId,
        }
    })

    const fetchMessage = useCallback(async () => {
        const {data} = await getMessage().catch(e => e)
        await setMessage(data)
    }, []);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: true
        })

        fetchMessage().then(() => {
            setBlockedFlg(isBlockedUser(message.userId))

            dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })
        });

    }, [])

    const containerRef = useRef(null);

    return (
        <main ref={containerRef}>
            <Header title={'投稿'} type={1} reload={true} reloadFunc={fetchMessage}/>

            {!loading
                ? <div className="l-main c-h">
                    <ul className={classes.message_list}>
                        {!blockedFlg
                            ? <li>
                                <div className={classes.message_info}>
                                    <p>{message.screenName}</p>
                                    <span>{format(message.postTime, 'HH:mm')}</span>
                                </div>
                                <div className={classes.message_content}
                                     dangerouslySetInnerHTML={{__html: message.message}}/>
                                <div className={classes.message_actions}>
                                    <div className={classes.message_actions_left}>
                                          <span className={classes.message_actions_report}>
                                              {
                                                  state.user?.userId && state.user.userId !== message.userId ?
                                                      <>
                                                          <FontAwesomeIcon icon={faExclamationTriangle} onClick={() => {
                                                              setReportId(message.messageId)
                                                          }}/>
                                                          <FontAwesomeIcon icon={faBan} onClick={() => {
                                                              setBlockedUserId(message.userId)
                                                          }}/>
                                                      </>
                                                      :
                                                      ''
                                              }
                                          </span>
                                    </div>
                                    <div className={classes.message_actions_right}></div>
                                </div>
                            </li> : <li>
                                <div
                                    className={classes.message_content}>このユーザーはブロックされているため、コメントは表示されません。
                                </div>
                            </li>

                        }

                    </ul>
                </div> : ''
            }

            {/* 通報モーダル */}
            <MessageReport
                reportId={reportId}
                setReportId={setReportId}
                userId={state.user.userId}
                shardId={shardId}
                blockedUserId={message.userId}
                setBlockedUserId={setBlockedUserId}
                setBlockedFlg={setBlockedFlg}
            />
            <UserBan blockedUserId={blockedUserId} setBlockedUserId={setBlockedUserId} setBlockedFlg={setBlockedFlg}/>
        </main>
    );
}

export default CameraMessageDetail;
