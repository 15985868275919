import classes from '../../../css/modules/Member.module.scss';
import classNames from "classnames";
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Header} from '../../components/Header'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/pro-light-svg-icons'
import {SiteDispatchContext, SiteStateContext} from "../../providers/siteProvider";
import {useAxiosJson}from '../../providers/axiosProvider'
import {flip, scrollTop} from "../../utils/DOMUtil";
import {loadAssetPath} from "../../utils/CommonUtil";

const timestamp = Date.now();

const MemberIndex = () => {
    const {artistName} = useParams();
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const api = {
        getArtist: { url: () => `/${artistName}.json?${timestamp}`, method: 'GET'},
    }

    const [{data: artist}, getData] = useAxiosJson({
        url: api.getArtist.url(),
        method: api.getArtist.method,
    }, {manual: true})

    const fetchArtist = async() => {
        const {data} = await getData().catch(err => err);

        // fixme: sortは別出しでもいいかも
        data.chatrooms = data.chatrooms.sort((a, b) => a.sort - b.sort)

        await dispatch({
            type: 'CHANGE_CURRENT_ARTIST',
            payload: data
        })

        await scrollTop(document.querySelector('#member_list'));
    }

    useEffect(() => {
        fetchArtist()
    }, [artistName])

    // 最初に選択されたアーティストを先頭に持ってくる
    const [artists, setArtists] = useState([]);
    useEffect(()=> {
        setArtists(state.artists.filter( val =>  artistName === val.roomId).concat(state.artists.filter( val =>  artistName !== val.roomId)));
    }, [])

    const flipAnimation = (e) => {
        const targets = document.querySelectorAll('.artist_btn');
        const targetWrapper = document.querySelector('#artist_btns');
        flip(targets[0], e.currentTarget, targetWrapper);
    }

    return (
      <main>
          <Header title={state.currentArtist.name} type={1} reload={true} isTop={true} reloadFunc={fetchArtist}/>

          <aside className={classes.aside}>
              <ul id={'artist_btns'}>
                  {
                      artists.map((val,key) => (
                          <li key={key} className={ classNames('artist_btn', artistName === val.roomId ? classes.current : '')} onClick={ (e)=> { flipAnimation(e)} } >
                              <Link to={['/member', val.roomId].join('/')}>
                                  <img src={loadAssetPath(`artists/${val.roomId}/logo.png`)}  alt={val.name} />
                              </Link>
                          </li>
                        ))
                  }
              </ul>
          </aside>

          <div className="l-main c-ah bg-transparent">
            <ul className={classes.member_list} id="member_list">
                {
                    state.currentArtist.camera?.broadcast &&
                      <li>
                          <Link to={['/message/camera', state.currentArtist.roomId].join('/')}>
                              <img src={loadAssetPath(`artists/${state.currentArtist.roomId}/logo.png`)} alt={state.currentArtist.camera.name} />
                              <p>{state.currentArtist.camera.name}</p>
                              <FontAwesomeIcon icon={faAngleRight} size={'2x'}/>
                          </Link>
                      </li>
                }
          {
              state.currentArtist.chatrooms
                .map((val,key) => (
                  <li key={key}>
                      <Link to={['/message', artistName, val.code].join('/')}>
                          <img src={loadAssetPath(`artists/${state.currentArtist.roomId}/members/${val.code}.jpg`)} alt={val.name} />
                          <p>{val.name}</p>
                          <FontAwesomeIcon icon={faAngleRight} size={'2x'}/>
                      </Link>
                  </li>
                ))
          }
          </ul>
          </div>
      </main>
    );
}

export default MemberIndex;
