import {LoadingModal} from "../../components/LoadingModal";

export const TopLayout = ({children}) => {
    return (
      <section>
          <LoadingModal/>
          {children}
      </section>
    );
}
