import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, HashRouter as Router} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import './css/Common.scss'
import Index from './js/pages/';
import MemberIndex from './js/pages/Member/';
import MessageIndex from './js/pages/Message/';
import MessageDetail from './js/pages/Message/detail';
import MessageCamera from './js/pages/Message/camera';
import CameraMessageDetail from "./js/pages/Message/cameraDetail";
import UserEdit from './js/pages/User/edit';
import NotFound from "./js/pages/Errors/NotFound";
import {SiteProvider, RouteWrapper, VersionCheck} from './js/providers/siteProvider';
import {CommonLayout} from './js/pages/layouts/CommonLayout'
import {TopLayout} from "./js/pages/layouts/TopLayout";
import {setProperty, setClientHeight} from './js/utils/DOMUtil';

if (process.env.NODE_ENV !== 'production') {
    const {whyDidYouUpdate} = require('why-did-you-update');
    whyDidYouUpdate(React);
}

const App = () => {

    useEffect(()=> {
        window.onload = () => {
            setProperty();
        }
    }, [])

    return(
      <React.StrictMode>
          <SiteProvider>
              <Router>
                  <Switch>
                      <RouteWrapper exact path="/" component={Index} layout={TopLayout}/>
                      <RouteWrapper path="/member/:artistName" component={MemberIndex} layout={CommonLayout} />
                      <RouteWrapper exact path="/message/camera/:roomId" component={MessageCamera} layout={CommonLayout} />
                      <RouteWrapper exact path="/message/camera/:roomId/:shardId/:messageId" component={CameraMessageDetail} layout={CommonLayout} />
                      <RouteWrapper exact path="/message/:artistName/:memberId" component={MessageIndex} layout={CommonLayout} />
                      <RouteWrapper exact path="/message/:artistName/:memberId/:messageId" component={MessageDetail} layout={CommonLayout} />
                      <RouteWrapper exact path="/user/edit" component={UserEdit} layout={CommonLayout} />
                      <Route path="*" component={NotFound} layout={CommonLayout} />
                  </Switch>
              </Router>
          </SiteProvider>
      </React.StrictMode>
    )
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
