import classes from '../../css/modules/Top.module.scss';
import React, {useContext, useEffect, useRef, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useAxiosApi, useAxiosJson}from '../providers/axiosProvider'
import {SiteStateContext, SiteDispatchContext} from '../providers/siteProvider'
import classNames from "classnames";
import {Timer} from "../utils/TimerUtil";

const timestamp = Date.now();

const api = {
    getArtists: { url: () => `/artists.json?${timestamp}`, method: 'GET'},
    getUser: { url: () => '/user', method: 'GET'},
    getAppInfo: {url: () => '/app/info', method: 'GET'},
}

const Index = () => {
    const query = new URLSearchParams(useLocation().search);
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [appVersion, setAppVersion] = useState(1)
    const versionCheckIntervalRef = useRef(null)

    const [{data: artistsData, error}, getArtistData] = useAxiosJson({
        url: api.getArtists.url(),
        method: api.getArtists.method,
    }, {manual: true})

    const [{data: userData}, getUserData] = useAxiosApi({
        url: api.getUser.url(),
        method: api.getUser.method,
        params: {
            customerCode: query.get('customer_code')
        }
    }, {manual: true})

    const [{data: appInfoData}, getAppInfo] = useAxiosApi({
        url: api.getAppInfo.url(),
        method: api.getAppInfo.method,
    }, {manual: true})

    useEffect(()=> {
        initialize()
        versionCheckIntervalRef.current = new Timer(checkVersion, 60000)

        return () => {
            if(versionCheckIntervalRef.current) {
                return versionCheckIntervalRef.current.stop()
            }
        }
    }, [])

    const fetchArtists = async ()=> {
        const {data} = await getArtistData().catch(err => err);

        return dispatch({
            type: 'CHANGE_ARTISTS',
            payload: data.artists
        })
    }

    const fetchUser = async () => {
        const {data} = await getUserData().catch(err => err);

        await dispatch({
            type: 'CHANGE_USER',
            payload: data
        })

    }

    const initialize = async() => {
        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: true
        })

        await fetchArtists()
        // customerCodeパラメータがある場合、ユーザー取得APIを叩く
        if(query.get('customer_code')) {
            await fetchUser()
        }

        await checkVersion()

        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: false
        })
    }

    const checkVersion = async () => {
        console.log('call check version..')
        const {data} = await getAppInfo()
        setAppVersion(parseInt(data.appVersion))
    }

    const reload = () => {
        const timestamp = Date.now();
        const url = new URL(window.location);

        url.search = '';
        url.searchParams.append('timestamp', String(timestamp));

        window.location.href = url.href
    }

    return !'appVersion' in window || parseInt(window.appVersion) < appVersion
    ? <>
            <div style={{padding: '20px 15px'}}>
                <div className={"error_text"}>情報が更新されましたので、ページの再読み込みが必要です。</div>
                <div style={{margin: '10px 0'}}>
                    <button className={`btn_link cyan`} onClick={reload}>再読み込み</button>
                </div>
            </div>
        </>
    : <main>
            <aside className={classes.aside}>
                <ul id={'artist_btns'}>
                    {
                        state.artists.map((val,key) => (
                            <li key={key} className={ classNames('artist_btn')} >
                                <Link to={['/member', val.roomId].join('/')}>
                                    <img src={[`${process.env.REACT_APP_ASSET_ENDPOINT}`, 'artists', val.roomId, 'logo.png'].join('/')} alt={val.name} />
                                </Link>
                            </li>
                        ))
                    }
                </ul>

            </aside>

            <div className="l-main c-a">
                <div className={classes.artist_names}>
                    <ul>
                        {
                            state.artists.map((val,key) => (
                                <li key={key} className={ classNames('artist_btn')} >
                                    <Link to={['/member', val.roomId].join('/')}>
                                        {val.name}
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </main>
}

export default Index;
