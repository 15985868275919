import classes from '../../../css/modules/Message.module.scss';
import React, {useContext, useEffect, useState, useRef, useCallback} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Header} from '../../components/Header'
import Accordion from '../../components/Accordion'
import {SiteDispatchContext, SiteStateContext} from "../../providers/siteProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faReply} from "@fortawesome/pro-solid-svg-icons";
import {format, now} from "../../utils/DateUtil";
import {useAxiosApi} from "../../providers/axiosProvider";
import {Timer} from "../../utils/TimerUtil";
import {scrollBottom, checkScrollBottom} from "../../utils/DOMUtil";
import {MessageInputComponent} from "../../components/MessageInput";
import classNames from "classnames";
import _ from "lodash";
import {getBlockedUsers} from "../../utils/BlockedUsers";

const api = {
    getMessages: {url: () => '/room/list', method: 'GET'},
    postMessage: { url: () => '/room/message', method: 'POST'},
    likeMessage: { url: () => '/room/favorite', method: 'POST'},
}

const MessageInput = ({addMessage, changeFocus}) => {
    const {artistName, memberId} = useParams();
    const state = useContext(SiteStateContext)

    const [{data, error}, postMessage] = useAxiosApi({
        url: api.postMessage.url(),
        method: api.postMessage.method,
    }, {manual: true})

    const msgObj = {
        roomId: artistName,
        memberId: memberId,
        userId: state.user?.userId,
        postTime: now(),
        screenName: state.user?.screenName,
        replyCount: 0,
        favoriteCount: 0,
    };

    return (
      <MessageInputComponent addListFunc={addMessage} changeFocus={changeFocus} submitFunc={postMessage} msgObj={msgObj} placeHolder={'新しいメッセージを入力...'} />
    )
}

const MessageIndex = () => {
    const [messageList, setMessageList] = useState({});

    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const {artistName, memberId} = useParams();

    const [{data: messageData , error}, getMessages] = useAxiosApi({
        url: api.getMessages.url(),
        method: api.getMessages.method,
    }, {manual: true})

    const [{data: likeData}, likeMessage] = useAxiosApi({
        url: api.likeMessage.url(),
        method: api.likeMessage.method,
    }, {manual: true})

    // useRefを使い、Timerのスコープを定める
    const intervalRefFetch = useRef(null);
    const timeoutRef = useRef([]);
    // setIntervalで参照される stateだと更新されないため
    const lastMessageIdRef = useRef(null)
    const messagesListRef = useRef(messageList);
    // 最下部まで到達したか
    const bottomRef = useRef(true);
    const loadedRef = useRef(false)

    useEffect(() => {

        initialize().then(()=> {
            containerRef.current.addEventListener('touchmove', _.throttle(() => {
                if (checkScrollBottom()) {
                    bottomRef.current = true;
                    fetchMessages().then(() => {
                        intervalRefFetch.current.start()
                    })
                }
            }, 1000))

            containerRef.current.addEventListener('touchmove', _.throttle(() => {
                if (!checkScrollBottom()) {
                    bottomRef.current = false;
                    intervalRefFetch.current.stop()
                    timeoutRef.current.map(t => clearTimeout(t))
                    setTimeout(()=> {
                        const msl = document.querySelectorAll('.message_list');
                        const lastChild = msl[msl.length - 1].lastElementChild;

                        if(lastChild?.dataset) {
                            lastMessageIdRef.current = lastChild.dataset.id
                        }
                    }, 100)

                }
            }, 100))
        })

        return () => {
            intervalRefFetch.current.stop()
        }

    }, [])

    useEffect(()=> {
        if(bottomRef.current) scrollBottom()
    }, [messageList])

    // 初回マウント時またはリロードボタンを押したときに作動
    const initialize = useCallback(async()=> {
        try {
            dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: true
            })

            dispatch({
                type: 'CHANGE_CURRENT_MEMBER',
                payload: state.currentArtist.chatrooms.find(el => String(el.code) === String(memberId))
            })

            await fetchMessages().then(()=> {
                scrollBottom();
                bottomRef.current = true;
                loadedRef.current = true;
                intervalRefFetch.current = new Timer(fetchMessagesSecond, process.env.REACT_APP_INTERVAL_FETCH_NEWS);
            })

            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })

        } catch(e) {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })

            await  dispatch({
                type: 'CHANGE_IS_ERROR',
                payload: true,
            })
        }
    }, [])

    const fetchMessages = useCallback(async() => {
        const params = {
            roomId: artistName,
            memberId: memberId
        }
        const {data} = await getMessages({params: params}).catch(e => e);
        lastMessageIdRef.current = data.lastMessageId;

        let messages = data.message;

        // ブロックユーザーのコメントを除外する
        const blockedUsers = getBlockedUsers()
        if (blockedUsers) messages = messages.filter(({userId}) => !blockedUsers.includes(userId))

        const dates = messages.reduce((acc, val) => {
            const date = val.postTime.split(' ')[0];
            if (!acc.includes(date)) acc.push(date)
            return acc;
        }, [])

        // 日付をkeyとした連想配列に整形
        messagesListRef.current = dates.reduce((acc, val) => {
            acc[val] = messages.filter(l => l.postTime.indexOf(val) !== -1)
            return acc;
        }, {})

        setMessageList(messagesListRef.current)
    }, []);

    const fetchMessagesSecond = useCallback(async() => {
        if(!bottomRef.current) return;

        const params = {
            roomId: artistName,
            memberId: memberId
        }
        if(lastMessageIdRef.current) params.lastMessageId = lastMessageIdRef.current;

        const {data} = await getMessages({params: params}).catch(e => e);
        let messages = data.message;

        if(messages.length > 0) {
            lastMessageIdRef.current = data.lastMessageId;

            // ブロックユーザーのコメントを除外する
            const blockedUsers = getBlockedUsers()
            if (blockedUsers) messages = messages.filter(({userId}) => !blockedUsers.includes(userId))

            const dates = messages.reduce((acc, val) => {
                const date = val.postTime.split(' ')[0];
                if (!acc.includes(date)) acc.push(date)
                return acc;
            }, [])

            let msl = {...messagesListRef.current};
            const recentMsg = messages[0];
            dates.map(d => {
                messages.map(m => {
                    const t = parseInt(m.messageId) - parseInt(recentMsg.messageId);
                    const tid = setTimeout(() => {
                        if(!bottomRef.current) clearTimeout(tid)
                        if (d in msl) {
                            msl[d] = msl[d].filter(ms => ms.messageId && ms);
                            msl[d].push(m)
                        } else {
                            msl[d] = [m]
                        }
                        messagesListRef.current = {...messagesListRef.current, ...msl};
                        setMessageList(messagesListRef.current)
                    }, t)

                    timeoutRef.current.push(tid)

                })
            })
        }

    }, []);

    const [likesList, setLikesList] = useState([]);
    let likesListCopy = [...likesList];

    const handleLikes = (messageId) => {
        const data = {
            roomId: artistName,
            memberId: memberId,
            messageId: messageId,
            userId: state.user.userId,
            operation: '',
        };

        if (likesListCopy.includes(messageId)) {
            likeMessage({data: {...data, operation: 'sub'}});
            setLikesList(likesListCopy.filter(el => el !== messageId))
        } else {
            likeMessage({data: {...data, operation: 'add'}});
            likesListCopy.push(messageId)
            setLikesList(likesListCopy)
        }
    }

    const addMessage = (msg) => {
        const date = format(msg.postTime, 'YYYY-MM-DD');
        const msgData = {...messageList};

       if(date in msgData) {
           msgData[date].push(msg)
       } else {
           msgData[date] = [msg];
       }

       setMessageList(msgData);
       scrollBottom();
    }

    const containerRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const changeFocus = () => {
        setFocus(!focus)
    };

    window.addEventListener('touchmove', ()=> {
        if(focus) {
            document.querySelector('.message_input_text').blur();
        }
    })

    return (
      <main ref={containerRef} className={classNames(focus ? 'focused' : '')}>
          <Header title={state.currentArtist.name} currentMember={state.currentMember} type={2} edit={true} reload={true} reloadFunc={initialize}/>

          {
              loadedRef.current && (
                error
                  ?
                  <div className={'l-main c-h c-red error_message_content'}>ルームメッセージが取得できませんでした。</div>
                  :
                  <div className="l-main c-h">

                      <div className={classes.message_list_wrapper}>
                          {
                              Object.keys(messageList).map((date, idx) => {
                                  return (
                                    <Accordion title={date} isOpen={idx === 0} key={idx}>
                                        <ul className={classNames(classes.message_list, 'message_list')}>
                                            {
                                                messageList[date].map((val, key) => (
                                                  <li key={val.messageId} data-id={val.messageId}>
                                                      <Link to={['/message', artistName, memberId, val.messageId].join('/')} key={key}>
                                                          <div className={classes.message_info}>
                                                              <p>{val.screenName}</p>
                                                              <span>{format(val.postTime, 'HH:mm')}</span>
                                                          </div>
                                                          <div className={classes.message_content}
                                                               dangerouslySetInnerHTML={{__html: val.message.replace(/\n/g, '<br />')}}/>
                                                          <div className={classes.message_actions}>
                                                              <div className={classes.message_action_left}>

                                                              </div>
                                                              <div className={classes.message_actions_right}>
                                                          <span className={classes.message_actions_reply}>
                                                              <FontAwesomeIcon icon={faReply} />
                                                              {val.replyCount}
                                                          </span>
                                                                  <span className={classes.message_actions_favorite}>
                                                              <button disabled={!state.user?.userId} onClick={(e) => {
                                                                  e.preventDefault();
                                                                  handleLikes(val.messageId)
                                                              }}>
                                                                  <FontAwesomeIcon icon={faHeart} className={likesList.includes(val.messageId) ? classes.is_liked : ''}/>
                                                              </button>
                                                                      {likesList.includes(val.messageId) ? val.favoriteCount + 1 : val.favoriteCount}
                                                          </span>
                                                              </div>
                                                          </div>
                                                      </Link>
                                                  </li>
                                                ))
                                            }
                                        </ul>
                                    </Accordion>
                                  )
                              })
                          }
                      </div>

                      <div className={classNames(classes.fixed_bottom, 'fixed_bottom')}>
                          <MessageInput addMessage={addMessage} changeFocus={changeFocus}/>
                      </div>


                  </div>
              )
          }

      </main>

    );
}



export default MessageIndex;
