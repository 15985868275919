import { useEffect, useRef } from "react";

export const usePrevious = (val) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current = val;
    }, [val])

    return ref.current;
}
