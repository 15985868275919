import React, {useReducer, useContext, useEffect} from 'react';
import {SiteStateContext} from "../../providers/siteProvider";
import {ErrorModal} from "../../components/ErrorModal";
import {LoadingModal} from "../../components/LoadingModal";

export const CommonLayout = ({children}) => {
    const state = useContext(SiteStateContext)

    // 直リンク対策
    useEffect(()=> {
        if(state.artists.length === 0) window.location.href = '/';
    }, [])

    return (
      <section>
          <LoadingModal/>
          <ErrorModal/>
          {children}
      </section>
    );
}
