import classes from '../../../css/modules/User.module.scss';
import {Header} from '../../components/Header'
import {useContext, useEffect, useState} from 'react'
import {SiteDispatchContext, SiteStateContext} from "../../providers/siteProvider";
import {useAxiosApi} from "../../providers/axiosProvider";

const api = {
    postScreenName: { url: () => 'screenname', method: 'POST'},
}

const UserEdit = (props) => {
    const [screenName, setScreenName] = useState('');
    const [error, setError] = useState({error: '', message: ''});
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [{data: user}, postScreenName] = useAxiosApi({
        url: api.postScreenName.url(),
        method: api.postScreenName.method,
        data: {
            userId: state.user.userId,
            screenName: screenName,
        }
    }, {manual: true})

    useEffect(()=> {
        setScreenName(state.user.screenName ? state.user.screenName : '');
    }, [])

    const handleSubmit = async() => {
        try {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: true
            })

            const {data} = await postScreenName();

            await dispatch({
                type: 'CHANGE_USER',
                payload: {...state.user, screenName: data.screenName}
            })

            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })

            return props.history.goBack();
        } catch (e) {
            setError(e.response.data)
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })
        }
    }

    return (
      <main>
          <Header title={'ニックネーム登録'} type={3} />

          <div className='l-main c-h'>
              <div className={classes.edit_input_area}>
                  <textarea className={'message_input_text'} value={screenName} placeholder={'ニックネームを登録'} onChange={(e) => { setScreenName(e.currentTarget.value)}}/>
                  { error.message && <p className='error_text'>{error.message}</p>}
              </div>

              <ul className={classes.edit_caution_list}>
                  <li>2文字以上、12文字以内で登録できます。</li>
                  <li>不適切なニックネームでの投稿は削除される場合があります。</li>
                  <li><span className={'c-red'}>誹謗中傷や不快な言動などの発言は固く禁じます。行き過ぎた内容には然るべき対応をすることがあります。</span>詳しくは、<a href={'https://www.fortunemusic.app/kiyakut/index.html'}>ご利用規約</a>をご覧ください</li>
              </ul>

              <button className={classes.edit_btn} disabled={screenName.length < 2 || screenName.length > 12} onClick={()=> { handleSubmit() }}>登録</button>
          </div>
      </main>
    );
}

export default UserEdit;
