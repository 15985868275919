const KEY = "blockedUsers"

export const getBlockedUsers = () => {
    return JSON.parse(localStorage.getItem(KEY))
}

export const setBlockedUser = (userId) => {
    const blockedUsers = getBlockedUsers()
    let addData

    if (blockedUsers) {
        blockedUsers.push(userId)
        addData = blockedUsers
    } else {
        addData = [userId]
    }

    localStorage.setItem(KEY, JSON.stringify(addData))
}

export const isBlockedUser = (userId) => {
    const blockedUsers = getBlockedUsers()
    return blockedUsers ? blockedUsers.includes(userId) : false
}
