import Axios from 'axios';
import {makeUseAxios} from 'axios-hooks'

/**
 * @see https://github.com/simoneb/axios-hooks/issues/98
 * APIのエンドポイントは環境変数からロード
 * 複数エンドポイント対応
 * カスタマイズしたaxiosのフックを定義
 * */
export const useAxiosApi = makeUseAxios({
    axios: Axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT })
});

export const useAxiosJson = makeUseAxios({
    axios: Axios.create({ baseURL: process.env.REACT_APP_JSON_ENDPOINT })
});
