import classes from '../../../css/modules/Message.module.scss';
import React, {useContext, useEffect, useState, useCallback, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {Header} from '../../components/Header'
import {SiteDispatchContext, SiteStateContext} from "../../providers/siteProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faReply, faExclamationTriangle, faBan} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import {format, now} from "../../utils/DateUtil";
import {useAxiosApi}from '../../providers/axiosProvider'
import {scrollBottom} from "../../utils/DOMUtil";
import {MessageInputComponent} from "../../components/MessageInput";
import {isBlockedUser, setBlockedUser} from "../../utils/BlockedUsers";

const api = {
    getMessage: {url: () => '/room/message', method: 'GET'},
    postMessage: {url: () => '/room/message', method: 'POST'},
    violationMessage: {url: () => '/room/violation', method: 'POST'}
}

const UserBan = ({blockedUserId, setBlockedUserId, setBlockedFlg}) => {
    const dispatch = useContext(SiteDispatchContext)


    const handleSubmit = async() => {
        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: true
        })

        setBlockedUser(blockedUserId)
        setBlockedFlg(true)

        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: false
        })
        setBlockedUserId(null);
    }

    return (
        <div className={classNames(classes.report, blockedUserId !== null ? classes.report_active : '')}>
            <div className={classes.report_inner}>
                <div className={classes.report_top}>
                    <p>このユーザーをブロックする</p>
                    <p className={classes.report_description}>ブロックすると、このユーザーはチャットルームで表示されなくなります。</p>
                </div>

                <div className={classes.report_bottom}>
                    <button className={classNames('btn_link cyan')} onClick={() => {
                        handleSubmit()
                    }}>このユーザーをブロックする
                    </button>
                    <button className={'btn_link gray'} onClick={() => {
                        setBlockedUserId(null)
                    }}>キャンセル
                    </button>
                </div>
            </div>
        </div>
    )
}

const MessageReport = ({reportId, setReportId, memberId, userId, roomId}) => {
    const [reason, setReason] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [{data}, violationMessage] = useAxiosApi({
        url: api.violationMessage.url(),
        method: api.violationMessage.method,
        data: {
            roomId: roomId,
            memberId: memberId,
            messageId: reportId,
            userId: userId,
            reason: reason
        }
    }, {manual: true})

    const handleSubmit = async () => {
        try {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: true
            })

            await violationMessage();

            setReportId(null);

        } catch (e) {
            switch (e.response.status) {
                case 400:
                    // 既に通報済みの場合は成功した時と同じ振る舞いにする
                    console.log(e.response.data.message)
                    setReportId(null);
                    break;
                default:
                    // その他のエラーの場合はエラーメッセージを表示する
                    console.log(e)
                    setErrorMessage(e.response.data.message)
            }
        } finally {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })
        }
    }

    const reportContent = (
        errorMessage ?
            <div className={classes.report_inner}>
                <div className={classes.report_top}>
                    <p>{errorMessage}</p>
                </div>

                <div className={classes.report_bottom}>
                    <button className={'btn_link gray'} onClick={() => {
                        setReportId(null)
                        setErrorMessage(null)
                    }}>閉じる
                    </button>
                </div>
            </div>
            :
            <div className={classes.report_inner}>
                <div className={classes.report_top}>
                    <p>この投稿を通報します</p>
                    <textarea placeholder="通報理由を記載してください" name="report_reason" value={reason}
                              onChange={(e) => {
                                  setReason(e.currentTarget.value)
                              }}/>
                </div>

                <div className={classes.report_bottom}>
                    <button className={classNames('btn_link cyan')} disabled={!reason} onClick={() => {
                        handleSubmit()
                    }}>このコメントを通報する
                    </button>
                    <button className={'btn_link gray'} onClick={() => {
                        setReportId(null)
                    }}>キャンセル
                    </button>
                </div>
            </div>
    )

    return (
        <div className={classNames(classes.report, reportId !== null ? classes.report_active : '')}>
            <div className={classes.report_inner}>
                {reportContent}
            </div>
        </div>
    )
}

const MessageInput = ({addMessage, parentMessageId, memberId, roomId, parentMessage}) => {
    const state = useContext(SiteStateContext)

    const [{data, error}, postMessage] = useAxiosApi({
        url: api.postMessage.url(),
        method: api.postMessage.method,
    }, {manual: true})

    const msgObj = {
        roomId: roomId,
        userId: state.user.userId,
        memberId: memberId,
        postTime: now(),
        screenName: state.user.screenName,
        parentMessageId: parentMessageId,
        replyCount: 0,
        favoriteCount: 0,
    };

    return (
        <MessageInputComponent addListFunc={addMessage} submitFunc={postMessage} msgObj={msgObj}
                               placeHolder={`${parentMessage.screenName}に返信`}/>
    )
};

const MessageDetail = () => {
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [message, setMessage] = useState({ reply: []});
    const [reportId, setReportId] = useState(null);
    const [blockedUserId, setBlockedUserId] = useState(null);
    const [blockedFlg, setBlockedFlg] = useState(false);

    const {artistName, memberId, messageId} = useParams();

    const [{data: messageData, loading}, getMessage] = useAxiosApi({
        url: api.getMessage.url(),
        method: api.getMessage.method,
        params: {
            roomId: artistName,
            memberId: memberId,
            messageId: messageId,
        }
    })

    const [likesList, setLikesList] = useState([]);

    const fetchMessage = useCallback(async() => {
        const {data} = await getMessage().catch(e => e)
        await setMessage(data)
    }, []);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: true
        })

        fetchMessage().then(()=> {
            setBlockedFlg(isBlockedUser(message.userId))

            dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })
        });

    }, [])

    let likesListCopy = [...likesList];

    const handleLikes = (id) => {
        if (likesListCopy.includes(id)) {
            setLikesList(likesListCopy.filter(el => el !== id))
        } else {
            likesListCopy.push(id)
            setLikesList(likesListCopy)
        }
    }

    const addMessage = (msg) => {
        const ms = {...message};
        ms.reply.push(msg)
        setMessage(ms)
        scrollBottom();
    }

    const containerRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const changeFocus = () => {
        setFocus(!focus)
    };

    window.addEventListener('touchmove', ()=> {
        if(focus) {
            document.querySelector('.message_input_text').blur();
        }
    })

    return (
      <main ref={containerRef} className={classNames( focus ? 'focused' : '')}>
          <Header title={'投稿'} type={1} reload={true} reloadFunc={fetchMessage}/>

          { !loading
                ? <div className="l-main c-h">
                      <ul className={classes.message_list}>
                          {!blockedFlg
                              ? <li>
                                  <div className={classes.message_info}>
                                      <p>{message.screenName}</p>
                                      <span>{format(message.postTime, 'HH:mm')}</span>
                                  </div>
                                  <div className={classes.message_content}
                                       dangerouslySetInnerHTML={{__html: message.message}}/>
                                  <div className={classes.message_actions}>
                                      <div className={classes.message_actions_left}>
                                    <span className={classes.message_actions_report}>
                                        {
                                            state.user?.userId && state.user.userId !== message.userId ?
                                                <>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} onClick={() => {
                                                        setReportId(message.messageId)
                                                    }}/>
                                                    <FontAwesomeIcon icon={faBan} onClick={() => {
                                                        setBlockedUserId(message.userId)
                                                    }}/>
                                                </>
                                                :
                                                ''
                                        }
                                    </span>
                                      </div>

                                      <div className={classes.message_actions_right}>
                                      <span className={classes.message_actions_reply}>
                                          <FontAwesomeIcon icon={faReply}/>
                                          {message.replyCount}
                                      </span>
                                          <span className={classes.message_actions_favorite}>
                                          <button disabled={!state.user?.userId} onClick={(e) => {
                                              e.preventDefault();
                                              handleLikes(message.messageId)
                                          }}>
                                              <FontAwesomeIcon icon={faHeart}
                                                               className={likesList.includes(message.messageId) ? classes.is_liked : ''}/>
                                          </button>
                                              {likesList.includes(message.messageId) ? message.favoriteCount + 1 : message.favoriteCount}
                                    </span>
                                      </div>
                                  </div>
                              </li> : <li>
                                  <div className={classes.message_content}>このユーザーはブロックされているため、コメントは表示されません。</div>
                              </li>

                          }

                      </ul>

                  { !blockedFlg && <ul className={classNames(classes.message_list, classes.message_list_reply)}>
                      {
                          message.reply.map((val, idx) => (
                              <li key={idx}>
                                  <div className={classes.message_info}>
                                      <p>{val.screenName}</p>
                                      <span>{format(val.postTime, 'HH:mm')}</span>
                                  </div>
                                  <div className={classes.message_content}
                                       dangerouslySetInnerHTML={{__html: val.message}}/>
                                  <div className={classes.message_actions}>
                                      <div className={classes.message_actions_left}>
                                            <span className={classes.message_actions_report}>
                                                {
                                                    state.user?.userId ?
                                                        <FontAwesomeIcon icon={faExclamationTriangle} onClick={() => {
                                                            setReportId(val.messageId)
                                                        }}/> : ''
                                                }
                                            </span>
                                      </div>

                                      <div className={classes.message_actions_right}>
                                             <span className={classes.message_actions_favorite}>
                                                  <button disabled={!state.user?.userId} onClick={(e) => {
                                                      e.preventDefault();
                                                      handleLikes(val.messageId)
                                                  }}>
                                                      <FontAwesomeIcon icon={faHeart}
                                                                       className={likesList.includes(val.messageId) ? classes.is_liked : ''}/>
                                                  </button>
                                                 {likesList.includes(val.messageId) ? val.favoriteCount + 1 : val.favoriteCount}
                                              </span>
                                      </div>
                                  </div>
                              </li>
                          ))
                      }
                  </ul>}
              </div> : ''
          }

          {/* 通報モーダル */}
          <MessageReport reportId={reportId} setReportId={setReportId} memberId={memberId} userId={state.user.userId}
                         roomId={artistName}/>
          <UserBan blockedUserId={blockedUserId} setBlockedUserId={setBlockedUserId} setBlockedFlg={setBlockedFlg} />

          { !blockedFlg && <div className={classes.fixed_bottom}>
              <MessageInput addMessage={addMessage} parentMessageId={messageId} memberId={memberId} roomId={artistName} parentMessage={message} changeFocus={changeFocus} />
          </div>}

      </main>
    );
}

export default MessageDetail;
