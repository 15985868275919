import classes from '../../css/modules/Modal.module.scss';
import React, {useContext, useEffect} from 'react'
import {SiteStateContext} from "../providers/siteProvider";
import classNames from "classnames";

export const LoadingModal = () => {
    const state = useContext(SiteStateContext);

    useEffect(()=> {
        if(state.isLoading) {
            if(document.getElementById('modal')) {
                console.log('modal')
                const html = document.querySelector("html");
                document.getElementById('modal').style.height = `${html.clientHeight}px`;
            }
        }
    }, [state.isLoading])

    return (
      state.isLoading ?
          <div id="modal" className={classNames(classes.modal, classes.modal_white)}>
              <div className={classes.modal_inner_transparent}>
                  <div className={classes.modal_content_center}>
                      <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                  </div>
              </div>
          </div>
        : ''
    );
}

