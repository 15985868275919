export const scrollBottom = () => {
    setTimeout(() => {
        const dc = document.documentElement;
        window.scroll(0, dc.scrollHeight - dc.clientHeight);
    }, 100)
}

export const scrollTop = (el) => {
    el.scrollTop = 0;
}


export const checkScrollBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const innerHeight = window.innerHeight;
    const scrollY = window.scrollY;

    return scrollY >= scrollHeight - innerHeight;
}


export const flip = (elm, target, list) => {
    const elmFirst = elm.getBoundingClientRect();
    const targetFirst = target.getBoundingClientRect();

    const elmPrev = elm.previousElementSibling;
    const targetPrev = target.previousElementSibling;

    // 並べ替え対象の要素に前の要素があれば、その要素の次、なければ先頭に挿入する
    list.insertBefore(
      elm,
      targetPrev ? targetPrev.nextElementSibling : list.firstElementChild
    );
    list.insertBefore(
      target,
      elmPrev ? elmPrev.nextElementSibling : list.firstElementChild
    );

    // ここで最終位置を記憶
    const elmLast = elm.getBoundingClientRect();
    const targetLast = target.getBoundingClientRect();

    // invert
    const elmDeltaX = elmFirst.left - elmLast.left;
    const elmDeltaY = elmFirst.top - elmLast.top;

    const targetDeltaX = targetFirst.left - targetLast.left;
    const targetDeltaY = targetFirst.top - targetLast.top;

    // transformを適用して、元の位置にあるよう見せかける
    elm.style.transform = `translate(${elmDeltaX}px, ${elmDeltaY}px)`;
    target.style.transform = `translate(${targetDeltaX}px, ${targetDeltaY}px)`;

    const _animate = (el, deltaX, deltaY) => {
        const animation = el.animate(
          [
              { transform: `translate(${deltaX}px, ${deltaY}px)` },
              { transform: `translate(0, 0)` },
          ],
          {
              duration: 300,
              easing: 'ease-out',
              fill: 'forwards',
          }
        );

    }

    _animate(elm, elmDeltaX, elmDeltaY);
    _animate(target, targetDeltaX, targetDeltaY);

}

// // innerHeight/widthを基準に1vh/vw単位の値を計算し,css propertyとしてセット
export const setProperty = () => {
    const vh = window.innerHeight / 100
    const vw = window.innerWidth / 100

    const root = document.documentElement

    root.style.setProperty('--vh', `${vh}px`)
    if (vh > vw) {
        root.style.setProperty('--vmax', `${vh}px`)
        root.style.setProperty('--vmin', `${vw}px`)
    } else {
        root.style.setProperty('--vmax', `${vw}px`)
        root.style.setProperty('--vmin', `${vh}px`)
    }
}

export const setClientHeight = () => {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.search(/android/) !== -1) {
        const html = document.querySelector("html");
        html.style.height = html.clientHeight + "px";
        const body = document.querySelector("body");
        body.style.height = html.clientHeight + "px";
    }
}

export const insertScript = (fileName, id) => {
    const script = document.createElement("script");
    script.src = fileName;
    script.id = id;
    document.body.appendChild(script);
}

export const deleteElement = (id) => {
    const target = document.querySelector(`#${id}`);
    if(target) {
        document.body.removeChild(target)
    }
}
