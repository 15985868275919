
const NotFound = () => {
  return (
    <div className="NotFound">
      <h1>404 Not Found.</h1>
    </div>
  );
}

export default NotFound;
