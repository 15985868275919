export class Timer {
    timerObj;
    fn;
    t;

    constructor(fn, t){
        this.fn = fn;
        this.t = t;
        this.timerObj = setInterval(fn, t);
    }

    stop = () => {
        if (this.timerObj) {
            clearInterval(this.timerObj);
            this.timerObj = null;
        }
        return this;
    }

    start = () => {
        if (!this.timerObj) {
            this.stop();
            this.timerObj = setInterval(this.fn, this.t);
        }
        return this;
    }

    reset = (newTime) => {
        this.t = newTime;
        return this.stop().start();
    }
}
