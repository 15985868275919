import classes from '../../../css/modules/Message.module.scss';
import React, {useContext, useEffect, useState, useRef, useCallback} from 'react'
import {Header} from '../../components/Header'
import {SiteDispatchContext, SiteStateContext} from "../../providers/siteProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import {format, now} from "../../utils/DateUtil";
import {Timer} from '../../utils/TimerUtil';
import {useAxiosApi}from '../../providers/axiosProvider'
import {checkScrollBottom, scrollBottom, insertScript, deleteElement} from "../../utils/DOMUtil";
import {MessageInputComponent} from "../../components/MessageInput";
import {isPermitted, loadAssetPath} from "../../utils/CommonUtil";
import {usePrevious} from "../../utils/Hooks";
import _ from 'lodash'
import {Link} from "react-router-dom";
import {getBlockedUsers} from "../../utils/BlockedUsers";

const api = {
    getMessages: { url: () => '/cam/list', method: 'GET'},
    postMessage: { url: () => '/cam/message', method: 'POST'},
    getMessage: { url: () => '/cam/message', method: 'GET'},
    violationMessage: {url: () => '/cam/violation', method: 'POST'},
    getInfo: { url: () => '/cam/info', method: 'GET'},
}

// ----- プレイヤー系 ----- //
// js読み込み完了をチェックし、プレイヤーの初期化を始める
const playerInit = () => {
    const tid = setInterval(()=> {
        if(typeof window.camPlayerInit !== 'undefined' && document.getElementById('player')) {
            console.log('cam init.')
            window.camPlayerInit()
            clearInterval(tid);
        }
    }, 100)
}

// video.jsのプレイヤーを削除する
const playerDispose = () => {
    const tid = setInterval(()=> {
        if(typeof window.camPlayerDispose !== 'undefined') {
            console.log('cam dispose.')
            window.camPlayerDispose()
            clearInterval(tid);
        }
    }, 100)
}

// ----- メッセージ系 ------ //
const messageDisplayLimit = 500;
// メッセージの数を制限
const sliceArrayByLimit = (list, limit = messageDisplayLimit) => list.slice(-limit);


// コンポーネント
const MessageInput = ({addMessage}) => {
    const state = useContext(SiteStateContext)

    const [{data, error}, postMessage] = useAxiosApi({
        url: api.postMessage.url(),
        method: api.postMessage.method,
    }, {manual: true})

    const msgObj = {
        roomId: state.currentArtist.roomId,
        userId: state.user.userId,
        postTime: now(),
        screenName: state.user.screenName,
    }

    return (
      <MessageInputComponent addListFunc={addMessage} submitFunc={postMessage} msgObj={msgObj} placeHolder={`新しいメッセージを入力...`} isInput={true}/>
    )
}

const MessageList = ({messages}) => {
    const state = useContext(SiteStateContext)

    return (
      <ul className={classes.message_list} id={'message_list'}>
          {
              messages.map((val, key) => (
                <li key={val.messageId} data-id={val.messageId}>
                    <Link to={['/message/camera', state.currentArtist.roomId, val.shardId, val.messageId].join('/')} key={key}>
                        <div className={classes.message_info}>
                            <p>{val.screenName}</p>
                            <span>{format(val.postTime, 'M/D HH:mm')}</span>
                        </div>
                        <div className={classes.message_content}
                             dangerouslySetInnerHTML={{__html: val.message}}/>
                        <div className={classes.message_actions}>
                            <div className={classes.message_action_left}></div>
                            <div className={classes.message_actions_right}></div>
                        </div>
                    </Link>
                </li>
              ))
          }
      </ul>
    )
}

const MessageCamera = () => {
    const [reportId, setReportId] = useState(null);
    const [shardId, setShardId] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [cameraInfo, setCameraInfo] = useState({
        broadcastStatus: 'inactive',
        news: '',
        message: '',
        newsTime: '',
        messageTime: '',
        isDisplayNews: '',
        isDisplayFixedMessage: '',
        fixedMessage: ''
    });

    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const [{data: messages, error, loading}, getMessages] = useAxiosApi({
        url: api.getMessages.url(),
        method: api.getMessages.method,
    }, {manual: true})

    const [{data: camInfo}, getInfo] = useAxiosApi({
        url: api.getInfo.url(),
        method: api.getInfo.method,
    }, {manual: true})

    // useRefを使い、Timerのスコープを定める
    const intervalRefFetch = useRef(null);
    const intervalFetchInfo = useRef(null);
    const timeoutRef = useRef([]);
    // setIntervalで参照される stateだと更新されないため
    const lastMessageIdRef = useRef(null)
    const messagesListRef = useRef(messageList);
    const cameraInfoRef = useRef(cameraInfo)
    const prevCameraInfo = usePrevious(cameraInfo)
    // 最下部まで到達したか
    const bottomRef = useRef(true);
    const loadedRef = useRef(false)

    useEffect(() => {

        initialize().then(() => {
            if(state.user.userId) insertScript(loadAssetPath('js/fortune-camera.js'), 'js-camera-fortune')

            containerRef.current.addEventListener('touchmove', _.throttle(() => {
                if (checkScrollBottom()) {
                    bottomRef.current = true;
                    fetchMessages().then(() => {
                        intervalRefFetch.current.start()
                    })
                }
            }, 1000))

            containerRef.current.addEventListener('touchmove', _.throttle(() => {
                if (!checkScrollBottom()) {
                    bottomRef.current = false;
                    intervalRefFetch.current.stop()
                    timeoutRef.current.map(t => clearTimeout(t))

                    setTimeout(()=> {
                        const lastChild = document.querySelector('#message_list').lastElementChild;
                        if(lastChild?.dataset) {
                            lastMessageIdRef.current = lastChild.dataset.id
                        }
                    }, 100)
                }
            }, 100))
        })

        return () => {
            intervalRefFetch.current.stop()
            intervalFetchInfo.current.stop()
            document.body.classList.remove('overflow-hidden');
            if(isPermitted(state) && cameraInfoRef.current.broadcastStatus === 'active') {
                playerDispose();
            }
            deleteElement('js-camera-fortune')
        }

    }, [])

    // messageListが更新されるたびに最下部までスクロール
    useEffect(()=> {
        if(bottomRef.current) scrollBottom()
    }, [messageList])

    // 初回マウント時またはリロードボタンを押したときに作動
    // message: lastMessageIdを付与せず実行 info: 通常実行
    const initialize = useCallback( async() => {
        try {
            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: true
            })
            await fetchMessagesInit()
            await fetchCamInfo().then(()=> {
                if(cameraInfoRef.current.broadcastStatus === 'active' && isPermitted(state)) {
                    playerInit();
                }

                scrollBottom()
            })
            if(!state.isRemovedCamNews) {
                document.body.classList.add('overflow-hidden');
            }

            loadedRef.current = true
            bottomRef.current = true;

            await dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })

            intervalRefFetch.current = new Timer(fetchMessagesSecond, process.env.REACT_APP_INTERVAL_FETCH_MESSAGE);
            intervalFetchInfo.current = new Timer(fetchCamInfo, process.env.REACT_APP_INTERVAL_FETCH_NEWS);

        } catch(e) {
            dispatch({
                type: 'CHANGE_IS_LOADING',
                payload: false
            })

            dispatch({
                type: 'CHANGE_IS_ERROR',
                payload: true,
            })
        }
    }, [state.isRemovedCamNews])

    const fetchMessagesInit = async() => {
        const params = {
            roomId: state.currentArtist.roomId,
        }

        try {
            const {data} = await getMessages({params: params})
            lastMessageIdRef.current = data.lastMessageId;
            let messages = data.message;
            // ブロックユーザーのコメントを除外する
            const blockedUsers = getBlockedUsers()
            if (blockedUsers) messages = messages.filter(({userId}) => !blockedUsers.includes(userId))

            messagesListRef.current = messages.length > messageDisplayLimit ? sliceArrayByLimit(messages) : messages;
            setMessageList(messagesListRef.current)
        } catch(e) {
            // console.log(e)
        }
    }

    const fetchMessages = useCallback(async () => {
        const params = {
            roomId: state.currentArtist.roomId,
        }

        if (lastMessageIdRef.current) params.lastMessageId = lastMessageIdRef.current;

        try {
            const {data} = await getMessages({params: params})
            const mslData = data.message.length > messageDisplayLimit ? sliceArrayByLimit(data.message) : data.message;

            if(mslData.length > 0) {
                lastMessageIdRef.current = data.lastMessageId;
                messagesListRef.current = [...messagesListRef.current, ...mslData];
                setMessageList(messagesListRef.current)
            }
        } catch(e) {
            // console.log(e)
        }

    }, []);

    const fetchMessagesSecond = useCallback(async () => {
        if(!bottomRef.current) return;
        const params = {
            roomId: state.currentArtist.roomId,
        }

        if (lastMessageIdRef.current) params.lastMessageId = lastMessageIdRef.current;

        try {
            const {data} = await getMessages({params: params})
            let messages = data.message;

            if(messagesListRef.current.length >= messageDisplayLimit) {
                messagesListRef.current = sliceArrayByLimit(messagesListRef.current, messageDisplayLimit - data.message.length);
            }

            if (messages.length > 0) {
                lastMessageIdRef.current = data.lastMessageId;

                // ブロックユーザーのコメントを除外する
                const blockedUsers = getBlockedUsers()
                if (blockedUsers) messages = messages.filter(({userId}) => !blockedUsers.includes(userId))

                const recentMsg = messages[0];

                messages.map((msg) => {
                    const t = parseInt(msg.messageId) - parseInt(recentMsg.messageId);
                    const tid = setTimeout(() => {
                        if(!bottomRef.current) clearTimeout(tid)
                        messagesListRef.current = [...messagesListRef.current, msg];
                        setMessageList(messagesListRef.current)
                    }, t);

                    timeoutRef.current.push(tid)
                })
            }
        } catch (e) {
            // console.log(e)
        }

    }, [])

    const fetchCamInfo = async () => {
        const params = {
            roomId: state.currentArtist.roomId,
        }

        try {
            const {data} = await getInfo({params: params}).catch(e => e)
            cameraInfoRef.current = data;
            setCameraInfo({...cameraInfo, ...cameraInfoRef.current})

            // WYSIWYGで空のタグが残っている場合の初期化処理
            const newsDiv =  document.createElement('div');
            const messageDiv =  document.createElement('div');
            newsDiv.innerHTML = cameraInfoRef.current.news
            messageDiv.innerHTML = cameraInfoRef.current.message

            if(!newsDiv.innerText) {
                cameraInfoRef.current = {...cameraInfoRef.current, news: ''}
                setCameraInfo(cameraInfoRef.current)
            }

            if(!messageDiv.innerText) {
                cameraInfoRef.current = {...cameraInfoRef.current, message: ''}
                setCameraInfo(cameraInfoRef.current)
            }
        } catch (e) {
            // console.log(e)
        }
    }

    useEffect(()=> {
        if(cameraInfoRef.current?.newsTime && prevCameraInfo?.newsTime) {

            // ニュースが更新されているかつニュースが空でない場合、お知らせエリアを活性化
            if(cameraInfoRef.current.newsTime !== prevCameraInfo.newsTime) {
                console.log('お知らせ更新')
                if(cameraInfoRef.current.news) {
                    console.log('更新かつお知らせが空ではない', cameraInfoRef.current.news)
                    openCamNews();
                    dispatch({
                        type: 'CHANGE_IS_OPEN_NEWS',
                        payload: true,
                    })
                }
            }

            // broadcastStatusが更新される かつ activeになった場合はプレイヤーを初期化
            if(cameraInfoRef.current.broadcastStatus !== prevCameraInfo.broadcastStatus) {

                // inactive/close -> activeになった場合
                if(cameraInfoRef.current.broadcastStatus === 'active') {
                    console.log('inactive/close -> active')
                    if(isPermitted(state)) {
                        playerInit();
                    }
                    openCamNews();
                    dispatch({
                        type: 'CHANGE_IS_OPEN_MESSAGE',
                        payload: true,
                    })

                }

                // active -> inactive/closeになった場合
                if(prevCameraInfo.broadcastStatus === 'active') {
                    console.log('active -> inactive or close')
                    if(isPermitted(state) && cameraInfoRef.current.broadcastStatus === 'active') {
                        playerDispose();
                    }

                }

            }
        }

    }, [cameraInfo])

    const addMessage = (msg) => {
        setMessageList([...messageList, msg])
        scrollBottom();
    }

    const openCamNews = () => {
        document.body.classList.add('overflow-hidden');
        dispatch({
            type: 'CHANGE_IS_REMOVE_CAM_NEWS',
            payload: false
        })
    }

    const closeCamNews = () => {
        if(!state.isRemovedCamNews) {
            document.body.classList.remove('overflow-hidden');
            dispatch({
                type: 'CHANGE_IS_REMOVE_CAM_NEWS',
                payload: true
            })
            dispatch({
                type: 'CHANGE_IS_OPEN_NEWS',
                payload: false,
            })
            dispatch({
                type: 'CHANGE_IS_OPEN_MESSAGE',
                payload: false,
            })
        }
    }

    // スマホ用 フォーカスされたらバーチャルキーボードを閉じるようにする
    const containerRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const changeFocus = () => {
        setFocus(!focus)
    };

    window.addEventListener('touchmove', () => {
        if (focus) {
            document.querySelector('.message_input_text').blur();
        }
    })

    // 表示/非表示算出
    const isRemoveNews = () => {
        console.group('お知らせエリア')
        console.log(cameraInfoRef.current)
        console.log(state)
        if(!state.isRemovedCamNews) {
            console.log('お知らせエリア未削除')
            if(!cameraInfoRef.current.isDisplayNews) {
                console.log('お知らせ追加フラグOFF')
                if(cameraInfoRef.current.message) {
                    console.log('管理者メッセージあり')
                    if(cameraInfoRef.current.broadcastStatus === 'active' && isPermitted(state)) {
                        console.log('放送中')
                        console.groupEnd()
                        return false;
                    } else {
                        console.log('放送前または終了')
                        console.groupEnd()
                        document.body.classList.remove('overflow-hidden');
                        return true;
                    }
                } else {
                    console.log('管理者メッセージなし')
                    console.groupEnd()
                    document.body.classList.remove('overflow-hidden');
                    return true;
                }

            } else {
                console.log('お知らせ追加フラグON')
                console.groupEnd()
                return false;
            }

        } else {
            console.log('お知らせエリア削除済')
            console.groupEnd()
            document.body.classList.remove('overflow-hidden');
            return true;
        }
    }

    useEffect(()=> {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.search(/android/) !== -1) {
            const tid = setInterval(()=> {
                if(document.getElementById('camera_container')) {
                    clearInterval(tid);
                    const html = document.querySelector("html");
                    if(isRemoveNews()) {
                        document.getElementById('camera_container').style.height = `auto`;
                    } else {
                        document.getElementById('camera_container').style.height = `${html.clientHeight - 70}px`;
                    }
                }

            }, 100)
        }
    }, [cameraInfo, state.isRemovedCamNews])

    return (
      <main ref={containerRef} className={classNames(focus ? 'focused' : '')}>
          <Header title={state.currentArtist.name} currentMember={state.currentArtist.camera} type={2} edit={true}
                  reload={true} reloadFunc={initialize}/>

          {
              loadedRef.current && (
                error
                  ?
                  <div className={'l-main c-h c-red error_message_content'}>ルームメッセージが取得できませんでした。</div>
                  :
                  <>
                      <div className="l-main c-h">

                          <div id="camera_container" className={classNames(classes.camera_container,  isRemoveNews() && classes.camera_container_removed)}>
                              {/* プレイヤー */}
                              {
                                  isPermitted(state) && (
                                    <div className={classes.video_container}>
                                        {cameraInfoRef.current.broadcastStatus === 'active' && <div className={classes.video}><video id="player" className="video-js vjs-fluid" data-room={state.currentArtist.roomId} data-event={state.currentArtist.roomId} data-user={state.user.userId} controls preload="auto" playsInline /></div>}
                                        {cameraInfoRef.current.broadcastStatus === 'inactive' && <div className={classes.video_poster}><img src={loadAssetPath(`artists/${state.currentArtist.roomId}/begin.png`)}/></div> }
                                        {cameraInfoRef.current.broadcastStatus === 'close' && <div className={classes.video_poster}><img src={loadAssetPath(`artists/${state.currentArtist.roomId}/end.png`)}/></div>}

                                        { cameraInfoRef.current.broadcastStatus === 'active' && cameraInfoRef.current.isDisplayFixedMessage && <div className={classes.video_fixed_message} dangerouslySetInnerHTML={{__html: cameraInfoRef.current.fixedMessage}} /> }
                                    </div>
                                  )
                              }

                              {/* カメラ注意事項・お知らせ*/}
                              <div className={classNames(classes.camera_caution, isRemoveNews() && classes.camera_caution_removed)}>
                                  <div className={classes.camera_caution_heading}>
                                      <img className={classes.camera_logo}
                                           src={loadAssetPath(`artists/${state.currentArtist.roomId}/logo.png`)}/>
                                      <p>定点カメラ部屋</p>
                                  </div>

                                  <div className={classNames(classes.camera_caution_content, isPermitted(state) && classes.camera_caution_content_video, cameraInfoRef.current.broadcastStatus === 'active' && cameraInfoRef.current.isDisplayFixedMessage && classes.camera_caution_content_video_fixed_message )}>
                                      { state.isOpenNews && cameraInfoRef.current.news && cameraInfoRef.current.isDisplayNews && <div dangerouslySetInnerHTML={{__html: cameraInfoRef.current.news}} /> }
                                      { state.isOpenMessage && cameraInfoRef.current.message && cameraInfoRef.current.broadcastStatus === 'active' && isPermitted(state) && <div dangerouslySetInnerHTML={{__html: cameraInfoRef.current.message}}/> }
                                  </div>

                                  <div className={classes.camera_caution_bottom}>
                                      <button className={classes.camera_caution_remove}
                                              onClick={closeCamNews}><FontAwesomeIcon icon={faTimes}/>このメッセージを非表示にする
                                      </button>
                                  </div>
                              </div>
                          </div>

                          <div className={classNames(classes.message_list_wrapper, isPermitted(state) && cameraInfoRef.current.isDisplayFixedMessage && classes.message_list_wrapper_video_fixed, isPermitted(state) && !cameraInfoRef.current.isDisplayFixedMessage && classes.message_list_wrapper_video)}>
                              <MessageList messages={messageList} reportId={reportId} setReportId={setReportId} shardId={shardId} setShardId={setShardId} />
                          </div>
                      </div>

                      <div className={classes.fixed_bottom}>
                          <MessageInput addMessage={addMessage} changeFocus={changeFocus} />
                      </div>
                  </>
              )
          }
      </main>
    );
}

export default MessageCamera;
