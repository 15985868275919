import classes from '../../css/modules/Header.module.scss';
import classNames from 'classnames';
import React, {useContext} from 'react'
import {useHistory, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons'
import { faUserEdit, faSync } from '@fortawesome/pro-regular-svg-icons'
import {SiteDispatchContext, SiteStateContext} from "../providers/siteProvider";
import {loadAssetPath, isPermitted} from "../utils/CommonUtil";

/**
 * headerはメモ化する
 * ※functionをpropsとして渡す際には、useCallbackで関数をメモ化してpropsに渡す
 * */
const HeaderComponent = ({title, type, currentMember, edit, reload, isTop, reloadFunc}) => {
    const history = useHistory();
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const reloadHandler = async () => {
        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: true
        })

        await reloadFunc();

        await dispatch({
            type: 'CHANGE_IS_LOADING',
            payload: false
        })
    }

    const HeaderLeftContent = ({...props}) => {
        switch (type) {
            case 1:
                return (
                  <>
                      <p>{title}</p>
                  </>
                )
            case 2:
                return (
                 <>
                     {
                         currentMember?.name && (
                           currentMember.name.indexOf('定点カメラ部屋') !== -1 || currentMember.name.indexOf('みんなでオンライントーク会') !== -1 || currentMember.name.indexOf('スペシャルトーク生配信') !== -1
                             ? <img src={loadAssetPath(`artists/${state.currentArtist.roomId}/logo.png`)} alt={currentMember.name} />
                             : <img src={loadAssetPath(`artists/${state.currentArtist.roomId}/members/${currentMember.code}.jpg`)} alt={currentMember.name} />
                         )
                     }
                     <p>{currentMember.name}</p>
                 </>
                )
            case 3:
                return (
                  <>
                      <FontAwesomeIcon icon={faUserEdit} size={'1x'}/>
                      <p>{title}</p>
                  </>
              )

            default:
                return (
                    <h2 {...props}>{title}</h2>
                )
        }
    }

    return (
      <header className={classNames(classes.header, 'header')}>
          <div className={classes.headerContent}>
              { !isTop
                    ?
                    <div className={classes.headerBtnBack} onClick={() => history.goBack()}>
                      <FontAwesomeIcon icon={faAngleLeft} size="2x" />
                    </div>
                    : ''
              }
              <h2 className={classNames(classes.headerInner, isTop ? classes.headerInnerMarginLeft : '')}>
                  <div className={classes.headerLeftContent}>
                      <HeaderLeftContent />
                  </div>
                  <div className={classes.headerRightContent}>
                      {/* userIdが空 -> 参加権限なしの場合は表示させない */}
                      { edit && isPermitted(state) ? <Link to={'/user/edit'}> <FontAwesomeIcon icon={faUserEdit} size={'1x'}/> </Link> : '' }
                      { reload ? <FontAwesomeIcon icon={faSync} size={'1x'} onClick={reloadHandler}/> : '' }
                  </div>
              </h2>
          </div>
      </header>
    );
}

export const Header = React.memo(HeaderComponent);
