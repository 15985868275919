import dayjs from 'dayjs'

/**
 *
 * @string date ex.) 2021-05-09 21:07:32
 * @string format ex.) YYYY/MM/DD
 * @returns {string}
 */
export const format = (date, format) => {
    const dateStr = String(date).replace(/-/g, '/');
    return dayjs(dateStr).format(format)
}

export const now = () => dayjs();
