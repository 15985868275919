import classes from '../../css/modules/Modal.module.scss';
import classNames from 'classnames';
import React, {useContext} from 'react'
import {useHistory, Link} from 'react-router-dom';
import {SiteDispatchContext, SiteStateContext} from "../providers/siteProvider";

export const ErrorModal = () => {
    const state = useContext(SiteStateContext)
    const dispatch = useContext(SiteDispatchContext)

    const handleClick = () => {
        return dispatch({
            type: 'CHANGE_IS_ERROR',
            payload: false,
        })
    }

    return (
      state.isError ?
          <div className={classNames(classes.modal, classes.modal_black)}>
              <div className={classes.modal_inner}>
                  <div className={classNames(classes.modal_content)}>
                      <p>エラーが発生しました。<br/>TOPに戻ってください。</p>
                      <Link className='btn_link cyan' to={'/'} onClick={handleClick}>TOPに戻る</Link>
                  </div>
              </div>
          </div>
        : ''
    );
}

