import React, {createContext, useReducer, useContext, useEffect, useRef, useState} from 'react';
import {Redirect, Route, useLocation} from "react-router-dom";
import {useAxiosApi}from './axiosProvider'
import {Timer} from '../utils/TimerUtil';

export const SiteStateContext = createContext();
export const SiteDispatchContext = createContext();

const initialState = {
    artists : [],
    currentArtist: {
        roomId: '',
        name: '',
        chatrooms: [],
        camera: { broadcast: '', name: '', image_url: ''}
    },
    currentMember: {
        id: '',
        name: '',
        image_url: '',
    },
    messages: [],
    user: {
        screenName: null,
        userId: '',
        permittedArtists: []
    },
    isError: false,
    isLoading: false,
    isRemovedCamNews: false,
    isOpenNews: true,
    isOpenMessage: true,
}

const _reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_ARTISTS':
            return {
                ...state,
                artists: action.payload
            };
        case 'CHANGE_CURRENT_ARTIST':
            return {
                ...state,
                currentArtist: action.payload
            };
        case 'CHANGE_MESSAGES':
            return {
                ...state,
                messages: action.payload
            };
        case 'CHANGE_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'CHANGE_CURRENT_MEMBER':
            return {
                ...state,
                currentMember: action.payload
            }
        case 'CHANGE_IS_ERROR':
            return {
                ...state,
                isError: action.payload
            }
        case 'CHANGE_IS_LOADING':
            return {
                ...state,
                isLoading: action.payload
            }
        case 'CHANGE_IS_REMOVE_CAM_NEWS':
            return {
                ...state,
                isRemovedCamNews: action.payload
            }
        case 'CHANGE_IS_OPEN_NEWS':
            return {
                ...state,
                isOpenNews: action.payload
            }
        case 'CHANGE_IS_OPEN_MESSAGE':
            return {
                ...state,
                isOpenMessage: action.payload
            }
        default :
            return state
    }
}

export const SiteProvider =  ({children}) => {
    const [state, dispatch] = useReducer(_reducer, initialState)
    // useEffect(() => {
    //     console.log("state", state)
    // }, [state])
    return (
      <SiteStateContext.Provider value={state}>
          <SiteDispatchContext.Provider value={dispatch}>
              {children}
          </SiteDispatchContext.Provider>
      </SiteStateContext.Provider>
    )
}


export const RouteWrapper = ({component: Component, layout: Layout, ...rest}) => {
    return (
      <Route {...rest} render={(props) =>
        <Layout {...props}>
            <Component {...props} />
        </Layout>
      }/>
    );
}

// fixme: TOPページに組み込んでいるが思想としてはイケてそうなので残しておく
// export const VersionCheck = ({children}) => {
//     const [appVersion, setAppVersion] = useState(1)
//
//     const api = {
//         getAppInfo: {url: () => '/app/info', method: 'GET'},
//     }
//
//     const [{data, error}, getAppInfo] = useAxiosApi({
//         url: api.getAppInfo.url(),
//         method: api.getAppInfo.method,
//     }, {manual: true})
//
//     const reload = () => {
//         const timestamp = Date.now();
//         const url = new URL(window.location);
//         const href = window.location.href;
//
//         url.search = '';
//         url.searchParams.append('timestamp', String(timestamp));
//
//         window.location.href = url.href
//     }
//
//     const checkVersion = async () => {
//         const {data} = await getAppInfo()
//         setAppVersion(parseInt(data.appVersion))
//     }
//
//     useEffect(()=> {
//         (async()=> {
//             await checkVersion()
//         })()
//     }, [])
//
//     return !'appVersion' in window || parseInt(window.appVersion) < appVersion
//         ? <>
//             <div style={{padding: '20px 15px'}}>
//                 <div className={"error_text"}>情報が更新されましたので、ページの再読み込みが必要です。</div>
//                 <div style={{margin: '10px 0'}}>
//                     <button className={`btn_link cyan`} onClick={reload}>再読み込み</button>
//                 </div>
//             </div>
//         </>
//         : children
// }