import classes from '../../css/modules/Accordion.module.scss';
import {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDown} from '@fortawesome/pro-light-svg-icons'
import classNames from "classnames";

const Accordion = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);

    return (
      <div className={'accordion'}>
          <div className={classes.accordion_title}>
              <h2>{props.title}</h2>
          </div>
          <div className={classNames(classes.accordion_content, classes.is_open)}>
              {props.children}
          </div>
      </div>
    );
}



export default Accordion;
